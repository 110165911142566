/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */


/**
 * Splits a string into two parts at the specified index.
 * 
 * @param {string} str The string to be split
 * @param {number} index The index at which to split the string
 * @returns {Array<string>} The two parts of the string after splitting
 */
function _splitStringOnIndex(str, index) {
  return [str.slice(0, index), str.slice(index)];
}

/**
 * Replaces the current host of a url with the specified host.
 * 
 * @param {string} url The url whose host is to be changed
 * @param {string} host The new host to change to
 * @returns {string} The url updated with the specified host
 */
function _replaceUrlHost(url, host) {
  const splitUrl = url.split('://');
  const urlAfterHost = splitUrl[1].slice(splitUrl[1].indexOf('/'));
  return splitUrl[0] + '://'  + host + urlAfterHost;
}

/**
 * Given an image object with a url, changes the url to use dynamic thumbnailer and https.
 * 
 * Note: A dynamic thumbnailer url generated with atLeastAsLarge = true returns an image that is
 * at least as large in one dimension of the desired size. In other words, the returned image will
 * be at least as large, and as close as possible to, the largest image that is contained within a
 * box of the desired size dimensions.
 * 
 * If atLeastAsLarge = false, the dynamic thumbnailer url will give the largest image that is
 * smaller than the desired size in both dimensions.
 * 
 * @param {Object} simpleOrComplexImage An image object with a url
 * @param {string} desiredSize The desired size of the image ('<width>x<height>')
 * @param {boolean} atLeastAsLarge Whether the image should be at least as large as the desired
 *                                 size in one dimension or smaller than the desired size in both
 *                                 dimensions.
 * @returns {Object} An object with a url for dynamic thumbnailer
 */

export function image(simpleOrComplexImage = {}, desiredSize = '200x', atLeastAsLarge = true) {
  let image = simpleOrComplexImage.image || simpleOrComplexImage;
  if (!image) {
    return {};
  }
  if (!image.url) {
    return image;
  }
  if (!(Object.prototype.toString.call(image).indexOf('Object') > 0)) {
    throw new Error("Expected parameter of type Map");
  }
  if ((typeof desiredSize !== 'string') || (desiredSize == null)) {
    throw new Error(`Object of type string expected. Got ${typeof desiredSize}.`);
  }
  if (desiredSize.indexOf('x') === -1) {
    throw new Error("Invalid desired size");
  }
  if ((typeof atLeastAsLarge !== 'boolean') || (atLeastAsLarge == null)) {
    throw new Error(`Object of type boolean expected. Got ${typeof atLeastAsLarge}.`);
  }

  let desiredWidth, desiredHeight;
  let desiredDims = desiredSize.split('x');

  const [urlWithoutExtension, extension] = _splitStringOnIndex(image.url, image.url.lastIndexOf('.'));
  const [urlBeforeDimensions, dimensions] = _splitStringOnIndex(urlWithoutExtension, urlWithoutExtension.lastIndexOf('/') + 1);
  const fullSizeDims = dimensions.split('x');

  if (desiredDims[0] !== '') {
    desiredWidth = Number.parseInt(desiredDims[0]);
    if (Number.isNaN(desiredWidth)) {
      throw new Error("Invalid width specified");
    }
  } else {
    desiredWidth = atLeastAsLarge ? 1 : Number.parseInt(fullSizeDims[0]);
  }

  if (desiredDims[1] !== '') {
    desiredHeight = Number.parseInt(desiredDims[1]);
    if (Number.isNaN(desiredHeight)) {
      throw new Error("Invalid height specified");
    }
  } else {
    desiredHeight = atLeastAsLarge ? 1 : Number.parseInt(fullSizeDims[1]);
  }

  const urlWithDesiredDims = urlBeforeDimensions + desiredWidth + 'x' + desiredHeight + extension;

  const dynamicUrl = atLeastAsLarge
    ? _replaceUrlHost(urlWithDesiredDims, 'dynl.mktgcdn.com')
    : _replaceUrlHost(urlWithDesiredDims, 'dynm.mktgcdn.com');

  return Object.assign(
    {},
    image,
    {
      url: dynamicUrl.replace('http://', 'https://')
    }
  );
}